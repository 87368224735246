<script setup lang="ts">
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'

interface BaseStateProps {
  title?: string
  image?: string
  svg?: string
  alt?: string
  align?: 'left' | 'center' | 'right'
}

const props = withDefaults(defineProps<BaseStateProps>(), {
  align: 'center',
})

const alignmentClasses = computed(() => ({
  'items-start text-left': props.align === 'left',
  'items-center text-center': props.align === 'center',
  'items-end text-right': props.align === 'right',
}))
</script>

<template>
  <div
    class="flex flex-col items-start justify-center text-left"
    :class="alignmentClasses"
  >
    <slot name="header" />
    <div v-if="$slots.image || image || svg" class="mb-[30px] w-full md:mb-10">
      <div v-if="image" class="flex max-h-48 sm:max-h-[300px]">
        <base-image :src="image" :alt="alt" />
      </div>
      <div v-else-if="svg" class="w-full pt-[100%] sm:max-h-[300px]">
        <base-icon class="absolute inset-0" size="auto" :svg="svg" />
      </div>
      <slot name="image" />
    </div>
    <div
      v-if="title"
      class="mb-[5px] w-full truncate text-2xl font-bold md:text-3xl"
    >
      {{ title }}
    </div>
    <slot />
    <div v-if="$slots.footer" class="mt-10 flex flex-col">
      <slot name="footer" />
    </div>
  </div>
</template>
